import { SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { User } from './user';

export const AskStatus = {
  PENDING: { label: 'PENDING', bgColor: '#FFEAB2', labelColor: '#FFBA09' },
  DRAFT: { label: 'DRAFT', bgColor: '#FFEAB2', labelColor: '#FFBA09' },
  OPEN: { label: 'OPEN', bgColor: '#E4D1FF', labelColor: '#6200EE' },
  CONCLUDED: { label: 'CONCLUDED', bgColor: '#E4FFF6', labelColor: '#08BE81' },
  ACCEPTED: { label: 'ACCEPTED', bgColor: '#E4FFF6', labelColor: '#08BE81' },
  REJECTED: { label: 'REJECTED', bgColor: '#FFCACD', labelColor: '#FF000F' },
  YES: { label: 'YES', bgColor: '#E4FFF6', labelColor: '#08BE81' },
  NO: { label: 'NO', bgColor: '#FFCACD', labelColor: '#FF000F' },
  'TO AMEND': { label: 'TO AMEND', bgColor: '#FFC794', labelColor: '##FF7F09' }
};

export interface InputValue {
  value: number;
  name: string;
  type: string;
  path?: string;
}

export interface PhaseDurationType {
  commenting: number;
  'deliberation phase 1': number;
  'deliberation phase 2': number;
  'final ranking': number;
  'vote on the proposal': number;
}

interface UserInfo {
  firstname?: string;
  lastname?: string;
  name: string;
  surname?: string;
  avatar?: number;
}

export interface Ask {
  id?: number;
  title: string;
  community_id?: number;
  creator?: number;
  summary: string;
  start_at?: Date | string;
  end_at?: Date | string;
  created_at?: Date;
  updated_at?: Date;
  tags?: string;
  proposal?: string | any;
  proposal_content?: string;
  status?: string;
  phase?: string;
  material_description?: string;
  timezone?: string;
  attachments?: InputValue[];
  published_at?: Date | string;
  done_date?: Date | string;
  phase_durations?: string;
  quorum_percent?: number;
  total_rewards?: number;
  reward_id?: string;
  store_reward_tx_id?: string;
  final_votes?: string;
  creator_info?: UserInfo;
  asynchronous?: boolean;
  rewards_unit?: string;
  rewards_paid?: boolean;
  phase_status?: string;
}

export interface Comment {
  id?: number;
  ask_id: number | string;
  member_id: number;
  text: string | ReactNode;
  created_at?: Date;
  updated_at?: Date;
  vote?: number;
  rank?: number;
  original_text?: string;
}

export interface CommentStream extends CommentSummary {
  type: string;
  sx?: SxProps | any;
  action?: any;
  slide?: boolean;
  pair?: {
    top: number;
    bottom: number;
    theme_id: number;
  };
  rank_id?: number;
  awaitingTime?: number;
  from?: string;
  to?: number;
  created_at?: Date;
  updated_at?: Date;
  vote?: number;
  member_id: number;
  created_by?: Partial<User>;
  chat_stream?: string;
  phase?: string;
}

export interface CommentRanking {
  id?: number;
  user_id: number;
  comment_id: number;
  ask_id: number;
  rank: number;
  submitted?: boolean;
}

export interface Vote {
  id?: number;
  user_id: number;
  ask_id: number;
}

export interface CommentSummary {
  id?: number;
  theme_id?: number;
  ask_id: number | string;
  text: string | ReactNode;
  ranking?: number;
}

export interface AnalyticsVote {
  vote: string;
  total: number;
  percent: number;
}

export interface GraphData {
  comments?: any[];
  summaries?: any[];
  roundOne?: any[];
  roundTwo?: any[];
  ranking?: any[];
  topOne?: any[];
  topTwo?: any[];
  topThree?: any[];
  topFour?: any[];
}

export interface Axis {
  min: number;
  max: number;
}
export interface Analytics {
  totalParticipants: number;
  topComments: CommentStream[];
  usersVote: AnalyticsVote[];
  graph: GraphData;
  forumSummary?: string;
  axis?: Axis;
}
