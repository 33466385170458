import React, { useRef, useState } from 'react';
import { alpha, Badge, BadgeProps, Box, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import { styled } from '@mui/material/styles';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useNotification, useNotificationActions, useNotificationState } from 'src/state/hooks';
import useMobile from 'src/hooks/useMobile';
import NotificationList from './NotificationList';
import { useNavigate } from 'react-router-dom';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        color: ${theme.colors.alpha.black};
        &:hover {
          background-color: transparent;
        }
`
);

const DotBadge = styled(Badge)<BadgeProps & { hide: boolean }>(({ theme, hide }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: hide ? 'transparent' : theme.colors.primary.main,
    minWidth: '10px',
    minHeight: '10px',
    height: '10px',
    position: 'absolute',
    left: '16px',
    top: '7px'
  },
  '& .MuiBadge-badge:after': {
    boxShadow: 'none'
  },
  '& path': {
    stroke: 'white',
    fill: 'black'
  }
}));

function HeaderNotifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const isMobile = useMobile();
  const { fetchNotification, markAllNotificationAsRead, readNotificationIcon } = useNotificationActions();
  const { page, pageSize, totalPages, total, countUnread } = useNotificationState();
  const notifications = useNotification();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetch = async () => {
      await fetchNotification({
        page,
        pageSize,
        total,
        totalPages
      });
    };
    fetch();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const notificationBadgeHandler = () => {
    readNotificationIcon();

    if (isMobile) {
      navigate('/notifications');
      return;
    }

    setOpen(true);
  };

  const countNotificationUnread = React.useMemo(() => notifications.filter((notification) => !notification.read).length, [notifications]);

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButtonWrapper ref={ref} onClick={notificationBadgeHandler}>
          <NotificationsBadge
            badgeContent={countUnread}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              '.MuiBadge-badge': {
                backgroundColor: '#FF1943',
                color: 'white',
                fontSize: '14px',
                minWidth: '20px',
                height: '20px',
                padding: '5px',
                marginTop: '5px',
                marginRight: '10px'
              }
            }}
          >
            <DotBadge badgeContent=" " color="primary" hide={countUnread > 0 || countNotificationUnread === 0} overlap="circular" variant="dot">
              <Box>
                <NotificationsOutlinedIcon fontSize="large" />
              </Box>
            </DotBadge>
          </NotificationsBadge>
        </IconButtonWrapper>
      </Tooltip>
      {!isMobile && (
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={{ maxWidth: '350px', minWidth: '300px' }}>
            <Box sx={{ p: 2 }} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h3" sx={{ display: 'inline' }}>
                Notifications
              </Typography>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  float: 'right'
                }}
              >
                <Tooltip title="Mark all as read">
                  <IconButton
                    size="small"
                    onClick={() => {
                      markAllNotificationAsRead();
                    }}
                  >
                    <ListTwoToneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <NotificationList containerStyle={{ maxHeight: '300px', overflow: 'auto' }} />
          </Box>
        </Popover>
      )}
    </>
  );
}

export default HeaderNotifications;
