import { FC, ComponentType, Component as ReactComponent, PropsWithChildren, useEffect } from 'react';
import { Outlet, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import useAuth from 'src/hooks/useAuth';
import useRedirection from 'src/hooks/useRedirection';
import useSubscription from 'src/hooks/useSubscription';

interface SubscriptionGuardProps {}

const SubscriptionGuard: FC<PropsWithChildren<SubscriptionGuardProps>> = ({ children }) => {
  const { subscription, onChecking } = useSubscription();

  const { user } = useAuth();
  const { setRedirection } = useRedirection();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!onChecking && location.pathname !== '/plan') {
      // Only redirect to plan page if current user has subscription under this community scope
      // if (authorization && authorization?.canManageSubscription()) {
      const p = searchParams.get('p');

      // Give the ability to fetch loop (refreshing) subscription
      if ((!subscription || !subscription?.is_active) && Boolean(p) && user?.registered_as === 'facilitator') {
        return navigate(`${location.pathname}?p=1`);
      }

      setRedirection(location.pathname);

      // After registration or failed payment use case | ONLY for a facilitator
      if ((!subscription || !subscription?.is_active) && user?.registered_as === 'facilitator') {
        return navigate('/plan');
      }
    }
  }, [subscription, onChecking, user]);

  return <>{children || <Outlet />}</>;
};

export const withSubscription = <P extends object>(Component: ComponentType<P>) =>
  class WithSubscription extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <SubscriptionProvider>
          <Component {...(props as P)} />
        </SubscriptionProvider>
      );
    }
  };

export default withSubscription(SubscriptionGuard);
