import { FC } from 'react';
import { Box, Drawer, Hidden } from '@mui/material';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import { SidebarMenuProps } from 'src/types/sidebar';
import FixedBottomNavigation from '../BottomNavigation';
import useMobile from 'src/hooks/useMobile';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 120px;
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        border-right: 1px solid ${theme.colors.primary.main};
        height: 100%;
        padding-top: 6px;
        
        @media (min-width: ${theme.breakpoints.values.md}px) {
            position: fixed;
            z-index: 10;
        }
`
);

const BoxSidebar = styled(Box)(
  ({ theme }) => `
        height: 90%;
        overflow-y: auto;
        background: ${theme.colors.alpha.lavender[100]};
        
        /* Custom scrollbar styles */
        &::-webkit-scrollbar {
            width: 1px;
        }
        
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        
        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 3px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        justify-content: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

const Sidebar: FC<SidebarMenuProps> = () => {
  const isMobile = useMobile();
  return (
    <>
      <Hidden lgDown>
        <Drawer anchor="left" open variant="permanent">
          <SidebarWrapper>
            <TopSection>
              <Link to="/dashboard">
                <img alt="logo" height={60} width={60} src="/static/images/logo.svg" />
              </Link>
            </TopSection>
            <BoxSidebar>
              <SidebarMenu />
            </BoxSidebar>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
      {isMobile && <FixedBottomNavigation />}
    </>
  );
};

export default Sidebar;
