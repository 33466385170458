import CryptoJS from 'crypto-js';

export const displayAddress = (addr: string, start?: number, end?: number): string =>
  `${addr?.substring(0, start || 6)}...${addr?.substring(addr.length - (end || 4))}`;

/**
 * Generate a random string with a specified length
 *
 * @param length
 * @returns
 */
export const generateId = (length: number = 16): string => {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

export const destructureCryptedParams = (
  crypted: string
): { redirection: string; email_account: string; group: string; existing: boolean; name: string; surname: string; token: string } => {
  if (crypted) {
    try {
      const text = decodeURIComponent(crypted);

      // Replace the whitespace to + sign as formatted by default from origin
      const formatText = text?.replace(/ /g, '+');

      const decryptedText = crypted ? CryptoJS.AES.decrypt(formatText, process.env.REACT_APP_CRYPTO_KEY).toString(CryptoJS.enc.Utf8) : '';

      const decryptedObj = JSON.parse(decryptedText);

      return {
        redirection: decryptedObj.redirection,
        email_account: decryptedObj.email_account,
        group: decryptedObj.group,
        existing: decryptedObj.existing === 'true' ? true : false,
        name: decryptedObj.name,
        surname: decryptedObj.surname,
        token: decryptedObj.token
      };
    } catch (error) {
      console.log(error);
    }
  }
  return {
    redirection: null,
    email_account: null,
    group: null,
    existing: null,
    name: null,
    surname: null,
    token: null
  };
};
