import { useEffect, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import BadgeIcon from 'src/components/Svg/BadgeIcon';

interface Badge {
  color: string;
  label?: string;
}

interface MemberBadgeProps {
  vox: number;
  hideLabel?: boolean;
  iconSize?: number;
}

const resolveBadge = (count: number, theme: any) => {
  let badge: Badge = {
    color: 'inherit',
    label: null
  };

  if (count > 150) {
    badge = {
      color: theme.colors.badge.gold,
      label: 'Gold'
    };
  } else if (count > 90) {
    badge = {
      color: theme.colors.badge.silver,
      label: 'Silver'
    };
  } else if (count > 30) {
    badge = {
      color: theme.colors.badge.bronze,
      label: 'Bronze'
    };
  }

  return badge;
};

const MemberBadge = ({ vox, hideLabel, iconSize }: MemberBadgeProps) => {
  const theme = useTheme();
  const [badge, setBadge] = useState<Badge>();

  useEffect(() => {
    setBadge(resolveBadge(vox, theme));
  }, [vox]);

  return (
    <>
      <BadgeIcon
        sx={{ background: badge?.label ? theme.palette.common.white : 'transparent' }}
        fontSize={`${iconSize ? `${iconSize}px` : 'small'}` as 'inherit'}
        color={badge?.color as 'inherit'}
      />
      {!hideLabel && (
        <Typography ml={1} variant="h6" mt={1}>
          {badge?.label}
        </Typography>
      )}
    </>
  );
};

export default MemberBadge;
